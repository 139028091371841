import { map } from 'Map';

window.map = map;
// изменение размера карты
map.oldTabResize = y.f.onTabResize;
y.f.onTabResize = function(id, type) { 
	var x = document.getElementById('d' + id).childNodes[0].id;
	// console.log(x);
	// TODO
	if (x && x.indexOf('map')> -1 ) {
		setTimeout(function() { oe.map.map.leaflet.invalidateSize(); }, 0);
	}
	map.oldTabResize(id,type);
};
